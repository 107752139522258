import React from "react";
import { Link } from "gatsby";
import causeImage1 from "../../images/hgf.png";
import causeImage2 from "../../images/img13.jpg";
import causeImage3 from "../../images/img14.jpg";
// import avatar1 from "../../images/comment-avatar.jpg";
// import avatar2 from "../../images/comment-avatar2.jpg";
// import authorAvatar1 from "../../images/author-avatar.jpg";
// import authorAvatar2 from "../../images/hgf.png";
// import authorAvatar3 from "../../images/author-avatar3.jpg";
// import authorAvatar4 from "../../images/author-avatar4.jpg";
import TitleSidebar from "./TitleSidebar";

const CausesDetail = () => {
  return (
    <section className="causes-detail-area">
      <div className="container">
        <div className="row blog-content-wrap">
          <div className="col-lg-8">
            <div className="blog-content">
              <div className="blog-item">
                <div className="blog-img">
                  <img src={causeImage1} alt="" />
                </div>
                <div className="blog-inner-content">
                  <div className="inner-causes-box">
                    <h3 className="blog__title">
                      <Link to="/causes-detail">HENRY GIESSENBIER FELLOW</Link>
                    </h3>
                    <ul className="blog__list">
                      <li>
                        <i className="icon-heart"></i> Amount:{" "}
                        <span> <i className="fa fa-inr"></i> 3,000</span>
                      </li>
                      {/* <li>
                        <i className="fa fa-line-chart"></i> Raised:{" "}
                        <span>25,270</span>
                      </li> */}
                    </ul>
                  </div>
                  <div className="inner-causes-btn">
                    <Link to="/donate" className="theme-btn">
                      donate now
                    </Link>
                  </div>
                </div>
                <div className="blog-inner-content-2">
                  <p className="causes__text">
                    A Jaycee who is active member of a chapter and has contributed effectively to the movement in India and who has been an active member of an active chapter who contributes a sum of Rs. 3000/- along with the letter of confirmation from his/her own LOM President, conferred with the Honor of Henry Giessenbier Fellow by the National Governing Board.
                  </p>
                  <p className="causes__text">
                    The honoree will receive a pin, a card, a certificate and will be eligible for the special privileges of the JCI India Foundation.
                  </p>
                  {/* <div className="inner-causes-img-box row">
                    <div className="col-lg-6">
                      <div className="inner-causes-img-item inner-causes-img-item1">
                        <img src={causeImage2} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="inner-causes-img-item inner-causes-img-item2">
                        <img src={causeImage3} alt="" />
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="presentation-block">
                    <h3>
                      <i className="fa fa-file-pdf-o"></i> Our Presentation
                    </h3>
                    <button className="theme-btn" type="button">
                      <i className="fa fa-download"></i> download
                    </button>
                  </div> */}
                  {/* <div className="single-comment">
                    <div className="comment__boxed">
                      <h3 className="single__comment-title">Comments</h3>
                      <ul className="comments-list">
                        <li>
                          <div className="comment">
                            <div className="avatar-img">
                              <img
                                className="avatar__img"
                                alt=""
                                src={avatar1}
                              />
                            </div>
                            <div className="comment-body">
                              <div className="meta-data">
                                <h4 className="comment__author">
                                  David marks
                                  <span className="comment__date">
                                    3 hours ago
                                  </span>
                                </h4>
                              </div>
                              <div className="comment-content">
                                <a
                                  className="reply__btn theme-btn"
                                  href="#none"
                                >
                                  Reply
                                </a>
                                <p className="comment__text">
                                  Sending love. My nephews Nick and Anthony
                                  Salaber are your teammates, so I know the
                                  caliber person you are. Our whole family is
                                  sending our best to you and your family.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="comment">
                            <div className="avatar-img">
                              <img
                                className="avatar__img"
                                alt=""
                                src={avatar2}
                              />
                            </div>
                            <div className="comment-body">
                              <div className="meta-data">
                                <h4 className="comment__author">
                                  Christine Eve
                                  <span className="comment__date">
                                    4 hours ago
                                  </span>
                                </h4>
                              </div>
                              <div className="comment-content">
                                <a
                                  className="reply__btn theme-btn"
                                  href="#none"
                                >
                                  Reply
                                </a>
                                <p className="comment__text">
                                  You're a champ. Your in my thoughts and
                                  prayers every day. You're the best teammate a
                                  bloke could ask for and we're going to return
                                  the favour my being there for you every step
                                  along this journey. Stay strong
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="comment__form form-shared">
                      <h3 className="single__comment-title">Leave a Comment</h3>
                      <form action="#">
                        <div className="row">
                          <div className="col-lg-6 col-sm-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Full Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-6">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Email Address"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <textarea
                                className="textarea"
                                name="message"
                                placeholder="Leave a Comment"
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <button className="theme-btn submit__btn">
                              submit now
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sidebar-shared">
              {/* <div className="side-widget">
                <div className="author-box">
                  <div className="author__avatar">
                    <img src={authorAvatar1} alt="" />
                  </div>
                  <div className="author__detail">
                    <span className="author__meta">created april 11, 2018</span>
                    <h4 className="author__title">
                      Organizer: <a href="#none">Jessica Smith</a>
                    </h4>
                    <ul className="author__list">
                      <li>
                        <i className="fa fa-tag"></i> Education
                      </li>
                      <li>
                        <i className="fa fa-map-marker"></i> Wrightwood, Canada
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <TitleSidebar />
              {/* <div className="side-widget">
                <h2 className="widget__title">Share Cause</h2>
                <div className="side-share-profile">
                  <ul>
                    <li>
                      <a href="#none">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#none">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#none">
                        <i className="fa fa-pinterest"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#none">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CausesDetail;
